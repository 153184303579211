import { TreatmentProgramConfig } from 'global.constants'

import { apiClient } from '~services/apiClient'
import esiCarrierService from '~services/esiCarrierService'

import fetchCompanyEligibility from './eligibilityService'

interface EsiELID {
  accessCode?: string
  carrierId?: string
  subCompanyId?: number
  dateOfBirth: string
  lastName: string
  zipCode: string
}

interface FetchEsiEligibilityResponse {
  eligibilityRules?: {
    hasCoDevice: boolean
    hasNrt: boolean
  }
  errorMessage?: string
  nextStep?: string
  results: {
    accessCode: string
    eligibilityRules: {
      hasCoDevice: boolean
      hasNrt: boolean
    }
    productId: string
  }[]
  success: boolean
}

interface EsiEligibilityResult {
  treatmentAccessCode: TreatmentAccessCode
  treatmentPrograms: TreatmentProgramConfig
}

/**
 * Fetch an Express Scripts user's eligibility record
 */
export default async function fetchEsiEligibility(
  elid: EsiELID
): Promise<EsiEligibilityResult> {
  const treatmentAccessCode: TreatmentAccessCode = {
    alcohol: '',
    cigarettes: '',
    eCigarettes: '',
    opioids: '',
  }

  // TODO: Update if ESI adds cannabis/stimulants: https://quitgenius.atlassian.net/browse/COPS-1307?focusedCommentId=56610
  const treatmentPrograms: TreatmentProgramConfig = {
    alcohol: false,
    cannabis: false,
    chewingTobacco: false,
    cigarettes: false,
    cigarettesLearn: false,
    eCigarettes: false,
    eCigarettesLearn: false,
    opioids: false,
    stimulants: false,
  }

  const isEnableEsiLegacyEligibilityEndpointFeatureSwitch =
    import.meta.env.VITE_ENABLE_LEGACY_ESI_ELIGIBILITY_ENDPOINT === 'true'
  if (
    !isEnableEsiLegacyEligibilityEndpointFeatureSwitch ||
    (elid.subCompanyId &&
      esiCarrierService.carriersWithMultipleCarrierIds.some(
        (carrier) => carrier.companyId === elid.subCompanyId
      ))
  ) {
    const { data } = await fetchCompanyEligibility({
      slugCompanyId: 27,
      companyId: elid.subCompanyId,
      dateOfBirth: elid.dateOfBirth,
      lastName: elid.lastName,
      postalCode: elid.zipCode,
      accessCode: elid.accessCode,
    })
    if (data.treatmentPrograms) {
      for (const [treatmentProgram, { eligible, accessCode }] of Object.entries(
        data.treatmentPrograms
      )) {
        treatmentPrograms[treatmentProgram as keyof TreatmentProgramConfig] =
          eligible
        if (accessCode) {
          treatmentAccessCode[treatmentProgram as keyof TreatmentAccessCode] =
            accessCode
        }
      }
    }
    return {
      treatmentPrograms,
      treatmentAccessCode,
    }
  }
  const url = 'eligibility-api/checkGuestEligibility/27'
  return apiClient
    .put<FetchEsiEligibilityResponse>(url, {
      accessCode: elid.accessCode,
      carrierId: elid.carrierId,
      DOB: elid.dateOfBirth.replaceAll('-', ''),
      zipCode: elid.zipCode,

      // `lastName` should NOT be sent with `accessCode`
      ...(!elid.accessCode && { lastName: elid.lastName }),
    })
    .then((response) => {
      response.data.results.forEach((v) => {
        switch (v.productId) {
          case '814':
            treatmentAccessCode.alcohol = v.accessCode
            treatmentPrograms.alcohol = true
            break
          case '812':
            treatmentAccessCode.cigarettes = v.accessCode
            treatmentAccessCode.eCigarettes = v.accessCode
            treatmentPrograms.cigarettes = true
            treatmentPrograms.eCigarettes = true
            break
          case '815':
            treatmentAccessCode.opioids = v.accessCode
            treatmentPrograms.opioids = true
            break
        }
      })

      return {
        treatmentPrograms,
        treatmentAccessCode,
      }
    })
}
