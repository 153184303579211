import { FlowVersion } from 'flow-version'
import {
  TreatmentProgram,
  TreatmentProgramConfig,
  WorkingQuittingPriority,
} from 'global.constants'
import * as yup from 'yup'

import { Motivation } from '~pages/MotivationalQuestion'
import {
  SignupContextCompany,
  signupContextCompanySchema,
} from '~services/companyService/schema'

import { ProgressUser, User, progressUserSchema } from './user'

interface Unavailability {
  unavailablePrograms: TreatmentProgram[]
  availablePrograms: TreatmentProgram[]
  partial: boolean
}

/**
 * Used within {@link file://./../signup.machine.ts#signupMachine}.
 *
 * The impact on rejoining the flow should be considered when making changes.
 * Currently saved context will be validated using
 * {@link progressSignupContextSchema} and merged with the current machine
 * context. Unless there are product/business reasons, users should not be
 * blocked from rejoining the flow due to validation or merge issues.
 *
 * TODO: Consider versioning system for this and {@link ProgressSignupContext}.
 */
export interface SignupContext {
  flowVersion: FlowVersion
  availableTreatmentPrograms: TreatmentProgramConfig
  company: SignupContextCompany
  errors: {
    signIn: {
      loginFailed: boolean
    }
    signUp: {
      emailInUse: boolean
      passwordLength: boolean
    }
    accessCode: {
      noMatchingEsiMembers: boolean
    }
    dateOfBirth: {
      isNotMinimumAge: boolean
    }
  }
  isAutomaticAddictionSelection: boolean
  motivations?: Motivation[]
  unavailability: {
    state?: Unavailability
    age?: Unavailability
  }
  treatmentAccessCode: TreatmentAccessCode
  selectedAddiction?: TreatmentProgram
  polyAddictions?: TreatmentProgram[]
  quittingPriority?: WorkingQuittingPriority
  resetPassword: {
    cognitoClientId: string
    cognitoUserPoolId: string
    email: string
    backendErrors: {
      codeMismatch: boolean
      invalidPassword: boolean
      sthWentWrong: boolean
    }
  }
  shouldSaveUserProgress: boolean
  starterCallSchedulerData: {
    healthieUrl?: string
    metadata: string
    nylasUrl?: string
  }
  user: User
}

const treatmentProgramSchema: yup.MixedSchema<
  NonNullable<TreatmentProgram | undefined>,
  yup.AnyObject,
  undefined,
  ''
> = yup
  .mixed<TreatmentProgram>()
  .oneOf(Object.values(TreatmentProgram))
  .required()

const treatmentProgramArraySchema: yup.ArraySchema<
  NonNullable<NonNullable<TreatmentProgram | undefined>>[] | undefined,
  yup.AnyObject,
  NonNullable<NonNullable<TreatmentProgram | undefined>>[] | undefined,
  ''
> = yup.array(treatmentProgramSchema.required()).ensure()

const unavailabilitySchema: yup.ObjectSchema<Unavailability> = yup.object({
  unavailablePrograms: treatmentProgramArraySchema.required(),
  availablePrograms: treatmentProgramArraySchema.required(),
  partial: yup.boolean().required(),
})

/**
 * Yup schema of {@link TreatmentProgramConfig}.
 *
 * Should be updated when {@link TreatmentProgram}s added/removed. The impact
 * on users who completed the flow prior to the addition should be considered,
 * i.e. they shouldn't be blocked from rejoining the flow because their saved
 * progress does not contain the new {@link TreatmentProgram}.
 *
 */
const treatmentProgramConfigSchema: yup.ObjectSchema<TreatmentProgramConfig> =
  yup.object().shape({
    [TreatmentProgram.Alcohol]: yup.boolean().required(),
    [TreatmentProgram.Cannabis]: yup.boolean().optional().default(false),
    [TreatmentProgram.ChewingTobacco]: yup.boolean().required(),
    [TreatmentProgram.Cigarettes]: yup.boolean().required(),
    [TreatmentProgram.eCigarettes]: yup.boolean().required(),
    [TreatmentProgram.CigarettesLearn]: yup.boolean().required(),
    [TreatmentProgram.eCigarettesLearn]: yup.boolean().required(),
    [TreatmentProgram.Opioids]: yup.boolean().required(),
    [TreatmentProgram.Stimulants]: yup.boolean().optional().default(false),
  })

/**
 * The same as {@link SignupContext} with the exception of the user, which is
 * {@link ProgressUser} instead of {@link User}
 *
 * Used within:
 * @see {@link file://./../services/authServices.ts#B2BOnboardingProgress}
 * @see {@link file://./../../../utils/helpers.ts#getSanitizedContext}.
 */
export type ProgressSignupContext = Omit<
  SignupContext,
  'user' | 'availableTreatmentPrograms'
> & {
  user: ProgressUser
  availableTreatmentPrograms: Record<
    Exclude<
      TreatmentProgram,
      TreatmentProgram.Cannabis | TreatmentProgram.Stimulants
    >,
    boolean
  > & {
    [TreatmentProgram.Cannabis]?: boolean
    [TreatmentProgram.Stimulants]?: boolean
  }
}

/**
 * Yup schema of {@link ProgressSignupContext}. Used within
 * {@link file://./../services/authServices.ts#AuthService#getProgress} to
 * validate the progress response.
 *
 * Should be updated when {@link ProgressSignupContext}/{@link SignupContext}
 * is updated. The impact on users who completed the flow prior to the update
 * should be considered, i.e. they shouldn't be blocked from rejoining the flow
 * unnecessarily.
 *
 */
export const progressSignupContextSchema: yup.ObjectSchema<ProgressSignupContext> =
  yup.object({
    flowVersion: yup
      .mixed<FlowVersion>()
      .oneOf(Object.values(FlowVersion))
      .required(),
    availableTreatmentPrograms: treatmentProgramConfigSchema,
    company: signupContextCompanySchema,
    errors: yup.object({
      signIn: yup.object({
        loginFailed: yup.boolean().required(),
      }),
      signUp: yup.object({
        emailInUse: yup.boolean().required(),
        passwordLength: yup.boolean().required(),
      }),
      accessCode: yup.object({
        noMatchingEsiMembers: yup.boolean().required(),
      }),
      dateOfBirth: yup.object({
        isNotMinimumAge: yup.boolean().required(),
      }),
    }),
    isAutomaticAddictionSelection: yup.boolean().optional().default(false),
    motivations: yup
      .array()
      .of(yup.mixed<Motivation>().oneOf(Object.values(Motivation)).required())
      .optional(),
    unavailability: yup.object({
      state: unavailabilitySchema.optional().default(undefined), // Default required for it to be optional: https://github.com/jquense/yup/issues/772,
      age: unavailabilitySchema.optional().default(undefined), // Default required for it to be optional: https://github.com/jquense/yup/issues/772,
    }),
    treatmentAccessCode: yup.object({
      alcohol: yup.string().defined(),
      opioids: yup.string().defined(),
      cigarettes: yup.string().defined(),
      eCigarettes: yup.string().defined(),
    }),
    selectedAddiction: treatmentProgramSchema.required(),
    polyAddictions: treatmentProgramArraySchema.optional(),
    quittingPriority: yup.object().shape(
      [...Array(Object.values(TreatmentProgram).length)].reduce(
        (accumulator, _, i) => ({
          ...accumulator,
          [i + 1]: yup
            .object({
              quitting: treatmentProgramSchema.required(),
              ageAtEnrolment: yup.number().optional(),
              state: yup.string().optional(), // Could add state validation.
            })
            .optional()
            .default(undefined), // Default required for it to be optional: https://github.com/jquense/yup/issues/772
        }),
        {}
      )
    ),
    resetPassword: yup.object({
      cognitoClientId: yup.string().defined(),
      cognitoUserPoolId: yup.string().defined(),
      email: yup.string().defined(),
      backendErrors: yup.object({
        codeMismatch: yup.boolean().required(),
        invalidPassword: yup.boolean().required(),
        sthWentWrong: yup.boolean().required(),
      }),
    }),
    shouldSaveUserProgress: yup.boolean().required(),
    starterCallSchedulerData: yup.object({
      healthieUrl: yup.string().optional(),
      metadata: yup.string().defined(),
      nylasUrl: yup.string().optional(),
    }),
    user: progressUserSchema,
  })
